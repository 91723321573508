/**
 * EveryParts
 *
 * @author      Ukoo <help@every.parts> - https://www.ukoo.fr
 * @copyright   Ukoo 2024 - https://www.ukoo.fr
 * @license     see file: LICENSE.txt
 *
 * @version     1.8.3
 */
// Import components
import './components/_utilities';
import './components/_glossary';
import './components/_placeholder';
import './components/_quickadd';
import './components/_search-block';
import './components/_manufacturers-list';

// Import pages
import './pages/product-compatibilities';
import './pages/garage';
import './pages/model';
import './pages/category';
import {initRichGalleryFancybox, initUniversalTooltip} from './components/_utilities';
import {initProductCompatibilitiesTable} from './pages/product-compatibilities';

// == Boostrap select application
$(document).ready(() => {
  // secure_key should not be passed through the url
  $.ajaxSetup({
    type: 'POST',
    data: {
      parts_secure_key: ukooparts_secure_key
    }
  });

  initBootstrapSelect();
  obfuscateLinks();
  initSlickSlider();
  initRichGalleryFancybox();

  $('body').on('ukoopartsRefresh', () => { // specific refresh from cache module
    initProductCompatibilitiesTable();
    initBootstrapSelect();
    obfuscateLinks();
    initUniversalTooltip();
    initSlickSlider();
    initRichGalleryFancybox();
  });

  prestashop.on('updateProductList', (data) => {
    initUniversalTooltip();
  });

  // hide url parameter used to redirect customer after he unselect his active engine
  const queryString = window.location.search;

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('needRedirect')) {
      urlParams.delete('needRedirect');
      const newURL = window.location.href.split('?')[0];
      let newParams = urlParams.toString();
      newParams = newParams ? `?${newParams}` : '';
      window.history.replaceState(null, null, newURL + newParams);
    }
  }
});

function initBootstrapSelect() {
  $('select.ucpt-bselect:not(.ucpt-bselect--min)').select2({
    placeholder: $(this).data('placeholder'),
    width: 'resolve',
  });
  $('select.ucpt-bselect.ucpt-bselect--min').select2({
    placeholder: $(this).data('placeholder'),
    width: 'resolve',
    minimumInputLength: 3,
  });
}

function obfuscateLinks() {
  $(document).on('click', '[data-obfs]', function () {
    const thisAttr = $(this).attr('target');
    const thisLink = $(this).data('obfs');

    if (thisAttr !== '' && thisLink === '_self') {
      window.location.href = thisLink;
    } else if (thisAttr === '_parent') {
      window.parent.location.href = thisLink;
    } else if (thisAttr === '_blank') {
      window.open(thisLink);
    } else {
      window.location.href = thisLink;
    }
  });
}

function initSlickSlider() {
  if (typeof ukooparts_slick_enabled !== 'undefined' && ukooparts_slick_enabled) {
    $('[data-slick]').slick();
  }
}
