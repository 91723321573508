/**
 * EveryParts
 *
 * @author      Ukoo <help@every.parts> - https://www.ukoo.fr
 * @copyright   Ukoo 2024 - https://www.ukoo.fr
 * @license     see file: LICENSE.txt
 *
 * @version     1.8.3
 */
$(document).ready(() => {
  $('.ucpt-smoothscroll').click((e) => {
    e.preventDefault();
    const target = $(e.currentTarget).attr('href');
    const targetSilo = $(e.currentTarget).attr('data-siloing');

    if (target) {
      ucptScrollTo(target);
    } else if (targetSilo) {
      ucptScrollTo(targetSilo);
    }
  });
});

export function ucptScrollTo(target, offset = 0) {
  if (window.matchMedia('(min-width:0px) and (max-width: 1199px)').matches) {
    // Je teste sur la résolution/périphérique sur lequel on se trouve pour rajouter
    // un décalage du scroll équivalent à la hauteur du header fixe
    $('html, body').animate({scrollTop: $(target).offset().top - 58 - 10 + offset}, 500);
  } else {
    // Si on est sur Desktop
    $('html, body').animate({scrollTop: $(target).offset().top - 10 + offset}, 500);
  }
}

export function updateProductListDOM(data) {
  let listingSelectors;
  // prestashop.themeSelectors only available since PrestaShop 1.7.8
  if (prestashop.themeSelectors === undefined) {
    listingSelectors = {
      'searchFilters': '#search_filters',
      'activeSearchFilters': '#js-active-search-filters',
      'listTop': '#js-product-list-top',
      'listBottom': '#js-product-list-bottom',
      'listHeader': '#js-product-list-header',
      'list': '#js-product-list',
      'product': '.js-product',
    };
  } else {
    listingSelectors = prestashop.themeSelectors.listing;
  }

  $(listingSelectors.searchFilters).replaceWith(data.rendered_facets,);
  $(listingSelectors.activeSearchFilters).replaceWith(data.rendered_active_filters,);
  $(listingSelectors.listTop).replaceWith(data.rendered_products_top,);

  const renderedProducts = $(data.rendered_products);
  const productSelectors = $(listingSelectors.product);

  if (productSelectors.length > 0) {
    productSelectors.removeClass().addClass($(listingSelectors.product).first().attr('class'));
  }

  $(listingSelectors.list).replaceWith(renderedProducts);

  $(listingSelectors.listBottom).replaceWith(
    data.rendered_products_bottom,
  );

  if (data.rendered_products_header) {
    $(listingSelectors.listHeader).replaceWith(
      data.rendered_products_header,
    );
  }
}

export function initUniversalTooltip() {
  const ucptUniversalClass = '.ucpt-badge--universal';

  $(ucptUniversalClass).each((index, element) => {
    const $parentWrapper = $(element).parents('.js-product-miniature');
    const idProduct = $parentWrapper.data('id-product');

    if (!idProduct) {
      return false;
    }

    $parentWrapper.find(ucptUniversalClass)
    .attr('title', `<small>${  universal_tooltip_title  }</small>`)
    .attr('data-toggle', 'tooltip')
    .attr('data-html', 'true')
    .tooltip();

    return true;
  });
}

/**
 * init fancybox for rich content gallery on type, manufacturer and model pages
 */
export function initRichGalleryFancybox() {
  if ($.inArray($('body').attr('id'), ['module-ukooparts-enginetypes', 'module-ukooparts-manufacturers', 'module-ukooparts-models']) !== -1) {
    $('.ucpt-rich-gallery__link').fancybox({
      padding: 0,
    });
  }
}
