/**
 * EveryParts
 *
 * @author      Ukoo <help@every.parts> - https://www.ukoo.fr
 * @copyright   Ukoo 2024 - https://www.ukoo.fr
 * @license     see file: LICENSE.txt
 *
 * @version     1.8.3
 */
/* eslint-disable no-undef */
$(document).ready(() => {
  initProductCompatibilitiesTable();
});

export function initProductCompatibilitiesTable() {
  const itemsPerPage = $('#ucpt-compatibilities-pagination').data('items_per_page');
  const nbPages = $('#ucpt-compatibilities-pagination').data('total_pages');
  /* Ajax requests */
  let currentRequest = null;
  $('#ucpt-compatibilities-filters .ucpt-bselect').on('change', () => {
    const productId = $('#product_page_product_id').val();
    let manufacturerId = $('#ucpt-compatibilities-select-brand').val();
    let displacement = $('#ucpt-compatibilities-select-displacement').val();
    let modelId = $('#ucpt-compatibilities-select-model').val();
    let year = $('#ucpt-compatibilities-select-year').val();

    if (manufacturerId == null || manufacturerId === '' || manufacturerId === undefined) {
      manufacturerId = 0;
    }
    if (displacement == null || displacement === '' || displacement === undefined) {
      displacement = 0;
    }
    if (modelId == null || modelId === '' || modelId === undefined) {
      modelId = 0;
    }
    if (year == null || year === '' || year === undefined) {
      year = 0;
    }

    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }

    currentRequest = $.ajax({
      dataType: 'html',
      beforeSend() {
        if (currentRequest != null) {
          currentRequest.abort();
          currentRequest = null;
        }
        $('#ucpt-compatibilities-table').addClass('ucpt-spinner--is-spining').prepend('<div class="ucpt-spinner"></div>');
      },
      url: `/module/ukooparts/productcompatibility?ajax=true&productId=${productId}&manufacturerId=${manufacturerId}&modelId=${modelId}&displacement=${displacement}&year=${year}&page=1&perPage=${itemsPerPage}&nbPages=${nbPages}&page_name=${pageName}`,
      success(response) {
        const result = jQuery.parseJSON(response);
        if (!result) {
          $('#ucpt-compatibilities-table .js-everyparts-error').removeClass('d-none');
        } else {
          $('#ucpt-compatibilities-table .js-everyparts-error').addClass('d-none');
          $('#ucpt-compatibilities-results').html(result.data);
          $('#ucpt-compatibilities-pagination').html(result.pagination);
        }

        $('#ucpt-compatibilities-table').removeClass('ucpt-spinner--is-spining').find('.ucpt-spinner').remove();
      },
    });
  });

  $('#ucpt-compatibilities-pagination').on('click', 'a', (e) => {
    e.preventDefault();
    const page = $(e.currentTarget).attr('page');
    const productId = $('#product_page_product_id').val();
    let manufacturerId = $('#ucpt-compatibilities-select-brand').val();
    let displacement = $('#ucpt-compatibilities-select-displacement').val();
    let modelId = $('#ucpt-compatibilities-select-model').val();
    let year = $('#ucpt-compatibilities-select-year').val();

    if (manufacturerId == null || manufacturerId === '' || manufacturerId === undefined) {
      manufacturerId = 0;
    }
    if (displacement == null || displacement === '' || displacement === undefined) {
      displacement = 0;
    }
    if (modelId == null || modelId === '' || modelId === undefined) {
      modelId = 0;
    }
    if (year == null || year === '' || year === undefined) {
      year = 0;
    }

    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }

    currentRequest = $.ajax({
      dataType: 'html',
      beforeSend() {
        if (currentRequest != null) {
          currentRequest.abort();
          currentRequest = null;
        }
        $('#ucpt-compatibilities-table').addClass('is--loading');
      },
      url: `/module/ukooparts/productcompatibility?ajax=true&productId=${productId}&manufacturerId=${manufacturerId}&modelId=${modelId}&displacement=${displacement}&year=${year}&page=${page}&perPage=${itemsPerPage}&nbPages=${nbPages}&page_name=${pageName}`,
      success(response) {
        const result = jQuery.parseJSON(response);

        if (!result) {
          $('#ucpt-compatibilities-table .js-everyparts-error').removeClass('d-none');
        } else {
          $('#ucpt-compatibilities-table .js-everyparts-error').addClass('d-none');
          $('#ucpt-compatibilities-results').html(result.data);
          $('#ucpt-compatibilities-pagination').html(result.pagination);
        }

        $('#ucpt-compatibilities-table').removeClass('is--loading');
      },
    });
  });
}
