/**
 * EveryParts
 *
 * @author      Ukoo <help@every.parts> - https://www.ukoo.fr
 * @copyright   Ukoo 2024 - https://www.ukoo.fr
 * @license     see file: LICENSE.txt
 *
 * @version     1.8.3
 */
import {ucptScrollTo} from './_utilities';

$(document).ready(() => {
  $('.ucpt__parts-items__list__toggle').click((e) => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('open');
    let toggleText = '';

    if ($(e.currentTarget).hasClass('open')) {
      toggleText = $(e.currentTarget).data('text-hide');
    } else {
      toggleText = $(e.currentTarget).data('text-show');
    }
    $(e.currentTarget).parents('.ucpt__parts-items__list').find('.ucpt__parts-items__list__item--hidden').toggle();
    $(e.currentTarget).find('> b').text(toggleText);
    $(e.currentTarget).find('> i').toggleClass('fa-angle-up').toggleClass('fa-angle-down');

    ucptScrollTo($(e.currentTarget).attr('href'));
  });
});
