/**
 * EveryParts
 *
 * @author      Ukoo <help@every.parts> - https://www.ukoo.fr
 * @copyright   Ukoo 2024 - https://www.ukoo.fr
 * @license     see file: LICENSE.txt
 *
 * @version     1.8.3
 */
$(document).ready(() => {
  $('.ucpt-table form').submit((e) => {
    $(e.currentTarget).parents('.ucpt-table__list').addClass('ucpt-spinner--is-spining');
  });
});
